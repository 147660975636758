<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="text-center">
        <construction-component />
      </div>
      <div class="w-100 text-center">
        <h2 class="my-1">
          <strong>
            {{ $t('pages.weArePreparing') }}
          </strong>
        </h2>
        <p class="mb-2">
          {{ $t('pages.thisPageIsUnderConstruction') }}
        </p>

        <p class="text-center mt-2">
          <b-link :to="loginRoute()">
            <i class="icon-0-icons-dark-step-back" /> {{ $t('generic.backToHome') }}
          </b-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink } from 'bootstrap-vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ConstructionComponent from '@/views/error/ImageUnderConstruction.vue'

export default {
  components: {
    BLink,
    ConstructionComponent,
  },

  methods: {
    loginRoute() {
      return getHomeRouteForLoggedInUser()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
